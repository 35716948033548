import React, { Component } from 'react';

import Portafolio1 from './img/Portafolio1.png';
import Portafolio2 from './img/Portafolio2.png';
import Portafolio3 from './img/Portafolio3.png';
import Portafolio4 from './img/Portafolio4.png';
import Portafolio5 from './img/Portafolio5.png';
import Portafolio6 from './img/Portafolio6.png';
import Calendar from './img/Calendar.png';
import Test from './img/Test.png';

class IndexPortafolio extends Component {
  render() {
    return (
      <div className="w-full">
        <div className="container mx-auto px-4">
          <h3 className="text-4xl mt-12">Portafolio</h3>
          <div className=" bg-primary-900 w-20 h-0.5"></div>
          <div className="mt-6 mb-6">
            <button className="focus:outline-none whitespace-nowrap inline-flex items-center justify-center px-5 py-2 rounded-md shadow-sm text-xs font-medium text-gray-700 bg-white bg-gray-50 hover:bg-gray-100 border border-gray-300 mr-2" type="button">
              Todos
            </button>
            <button className="focus:outline-none whitespace-nowrap inline-flex items-center justify-center px-5 py-2 rounded-md shadow-sm text-xs font-medium text-white bg-white bg-primary-900 hover:bg-primary-800 border border-primary-900 mr-2" type="button">
              Administrable
            </button>
            <button className="focus:outline-none whitespace-nowrap inline-flex items-center justify-center px-5 py-2 rounded-md shadow-sm text-xs font-medium text-white bg-white bg-primary-900 hover:bg-primary-800 border border-primary-900 mr-2" type="button">
              Landing Page
            </button>
          </div>
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-white">
              {/* <div className="bg-black rounded-lg">
                <a href="https://evolution-landing.herokuapp.com/"><img className="block m-auto" src={ Portafolio1 }/></a>
                <div className="p-5">
                  <div className="flex items-center	">
                    <img src={ Calendar }/>
                    <span className="font-light	">31 de julio de 2019</span>
                  </div>
                  <div>
                    <p className="text-2xl">Diseño con HTML y CSS</p>
                  </div>
                  <p className="font-light">Publicado por Yerson Y. Campos D.</p>
                  <img src={ Test } className="mt-4" />
                </div>
              </div> */}
              {/* <div className="bg-black rounded-lg">
                <a href="https://app.yanki.pe/"><img className="block m-auto" src={ Portafolio2 }/></a>
                <div className="p-5">
                  <div className="flex items-center	">
                    <img src={ Calendar }/>
                    <span className="font-light	">31 de enero de 2021</span>
                  </div>
                  <div>
                    <p className="text-2xl">Admin Diseño HTML y CSS</p>
                  </div>
                  <p className="font-light">Publicado por Yerson Y. Campos D.</p>
                  <img src={ Test } className="mt-4" />
                </div>
              </div>
              <div className="bg-black rounded-lg">
                <a href="https://yanki.pe/"><img className="block m-auto" src={ Portafolio3 }/></a>
                <div className="p-5">
                  <div className="flex items-center	">
                    <img src={ Calendar }/>
                    <span className="font-light	">31 de fabrero de 2021</span>
                  </div>
                  <div>
                    <p className="text-2xl">Landing Page HTML y CSS</p>
                  </div>
                  <p className="font-light">Publicado por Yerson Y. Campos D.</p>
                  <img src={ Test } className="mt-4" />
                </div>
              </div> */}
              {/* <div className="bg-black rounded-lg">
                <a href="http://ceocv.sisreal.com/"><img className="block m-auto" src={ Portafolio4 }/></a>
                <div className="p-5">
                  <div className="flex items-center	">
                    <img src={ Calendar }/>
                    <span className="font-light	">10 de marzo de 2021</span>
                  </div>
                  <div>
                    <p className="text-2xl">Mi CV con HTML y CSS</p>
                  </div>
                  <p className="font-light">Publicado por Yerson Y. Campos D.</p>
                  <img src={ Test } className="mt-4" />
                </div>
              </div> */}
              {/* <div className="bg-black rounded-lg">
                <a href="#"><img className="block m-auto" src={ Portafolio5 }/></a>
                <div className="p-5">
                  <div className="flex items-center	">
                    <img src={ Calendar }/>
                    <span className="font-light	">20 de septiembre de 2019</span>
                  </div>
                  <div>
                    <p className="text-2xl">React, HTML y CSS</p>
                  </div>
                  <p className="font-light">Publicado por Yerson Y. Campos D.</p>
                  <img src={ Test } className="mt-4" />
                </div>
              </div> */}
              <div className="bg-black rounded-lg">
                <a href="https://pacificometal.com/"><img className="block m-auto" src={ Portafolio6 }/></a>
                <div className="p-5">
                  <div className="flex items-center	">
                    <img src={ Calendar }/>
                    <span className="font-light	">30 de junio de 2021</span>
                  </div>
                  <div>
                    <p className="text-2xl">Pacifico Metal S.A.C.</p>
                  </div>
                  <p className="font-light">Publicado por Yerson Y. Campos D.</p>
                  <img src={ Test } className="mt-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IndexPortafolio;