import React, { Component } from 'react';

import CelularBanner from './img/CelularBanner.svg';
import Cubo from './img/Cubo.svg';
import Work1 from './img/Work1.svg';
import Work2 from './img/Work2.svg';
import Work3 from './img/Work3.svg';
import Work4 from './img/Work4.svg';
import Laptop from './img/Laptop.png';

class IndexHome extends Component {
  render() {
    return (
      <>
      <div className="bg-primary-100 w-full">
        <div className="container mx-auto px-4">
          <div className="flex justify-between flex-col md:flex-row sm:flex-row">
            <div className="sm:w-9/12 pt-16 sm:pt-32">
              <h1 className="sm:text-7xl leading-tight pb-12 text-5xl text-center sm:text-left">Administra tu información en la nube con <span className="text-primary-900">Sis</span>real.</h1>
              <div className="pb-16 sm:pb-32">
                <button className="flex-shrink-0 bg-primary-900 text-white text-base font-semibold py-3 px-4 mr-3 rounded-lg shadow-md hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-900 focus:ring-offset-2 focus:ring-offset-primary-200" type="button">
                Regístrate Gratis
                </button>
                <button className="flex-shrink-0 bg-white text-primary-700 text-base font-semibold py-3 px-4 rounded-lg shadow-md border-2 border-primary-900 focus:outline-none" type="button">
                Saber Más
                </button>
              </div>
              <img className="mx-auto sm:mx-px" src={ Cubo }  alt="Cubo" />
            </div>
            <div className="pt-16">
              <img className="mx-auto sm:mx-px pb-4" src={ CelularBanner }  alt="IMG" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="container mx-auto px-4">
          <div className="flex justify-center py-6">
            <div className="w-9 h-9 border border-primary-900 text-primary-900 rounded-full animate-bounce flex justify-center items-center cursor-pointer">
              <svg class="w-6 h-6 text-amber-900" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      
      <div className="w-full">
        <div className="container mx-auto px-4 pt-8 pb-4">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
            <div>
              <div><img src={ Work1 } alt="Work1"/></div>
              <div className="text-lg font-bold pt-4 pb-2">Usamos Plantillas</div>
              <p>Para nosotros, “el mejor cambio, es el más justo”, esta premisa nos inspira continuamente a darte siempre el mejor servicio de compra y venta de dólares</p>
            </div>
            <div>
              <div><img src={ Work2 } alt="Work2"/></div>
              <div className="text-lg font-bold pt-4 pb-2">Diseños Personalizados</div>
              <p>Para nosotros, “el mejor cambio, es el más justo”, esta premisa nos inspira </p>
            </div>
            <div>
              <div><img src={ Work3 } alt="Work3"/></div>
              <div className="text-lg font-bold pt-4 pb-2">Desarrollo Funcional</div>
              <p>Para nosotros, “el mejor cambio, es el más justo”, esta premisa nos inspira  servicio de compra y venta de dólares</p>
            </div>
            <div>
              <div><img src={ Work4 } alt="Work4"/></div>
              <div className="text-lg font-bold pt-4 pb-2">Publicado en Hostinger</div>
              <p>Para nosotros, “el mejor cambio, es el más justo”, esta premisa nos inspira continuamente a l mejor compra y venta de dólares</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary-900 w-full mt-8">
        <div className="container mx-auto px-4">
          <div className="text-center text-white py-12">
            <h5 className="text-2xl">Empieza tus 15 <b>días gratis</b></h5>
            <h6 className="text-xl py-6 max-w-xl m-auto">Lorem ipsum dolor sit amet consectetur adipiscing elit duis urna torquent orci, cursus dictum vivamus fermentum feugiat lectus convallis ultrices purus vehicula. <b>Sisreal</b></h6>
            <div>
              <button className="flex-shrink-0 text-white text-base font-semibold py-3 px-4 rounded-lg shadow-md border-2 border-white focus:outline-none" type="button">
              Crear <b>cuenta</b>
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="w-full">
        <div className="container mx-auto px-4">
          <div className="flex justify-center py-6">
            <div className="w-9 h-9 border border-primary-900 text-primary-900 rounded-full animate-bounce flex justify-center items-center cursor-pointer">
              <svg class="w-6 h-6 text-amber-900" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="container mx-auto px-4 py-20">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 sm:col-span-8">
              <img src={ Laptop } alt="Laptop"/>
            </div>
            <div className="col-span-12 sm:col-span-4">
              <h5 className="text-2xl pt-10">Nuestro Sistema es Seguro <b><span className="text-primary-900">Sis</span>real</b></h5>
              <p className="py-6">Delivering faster and more personalized 
              support with shared screens 
              and cool design systems for Figma</p>
              <div>
              <button className="flex-shrink-0 bg-primary-900 text-white text-base font-semibold py-3 px-4 mr-3 rounded-lg shadow-md hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-900 focus:ring-offset-2 focus:ring-offset-primary-200" type="button">
              Ver más
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="container mx-auto px-4">
          <div className="flex justify-center py-6">
            <div className="w-9 h-9 border border-primary-900 text-primary-900 rounded-full animate-bounce flex justify-center items-center cursor-pointer">
              <svg class="w-6 h-6 text-amber-900" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default IndexHome;