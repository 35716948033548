import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Logo from './img/Logo.svg';
import Yerson from './img/Yerson.png';
import Bootstrap from './img/Bootstrap.svg';
import CSS from './img/CSS.svg';
import Laravel from './img/Laravel.svg';
import React from './img/React.svg';
import LogoFooter from './img/LogoFooter.svg';

import Mail from './img/Mail.svg';
import Celular from './img/Celular.svg';
import Maps from './img/Maps.svg';

import Facebook from './img/Facebook.png';
import Instagram from './img/Instagram.png';
import Twitter from './img/Twitter.png';
import Youtube from './img/Youtube.png';

import {
  BrowserRouter as Router,
  Switch,
  Route } from 'react-router-dom';
import IndexHome from './scenes/Public/Home';
import IndexPortafolio from './scenes/Public/Portafolio';

import {
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'

import './App.css';

const solutions = [
  { name: 'Seguridad', description: "Los datos de sus clientes estarán seguros y protegidos.", href: '#', icon: ShieldCheckIcon },
  {
    name: 'Integración',
    description: "Conéctese con herramientas de terceros que ya está utilizando.",
    href: '#',
    icon: ViewGridIcon,
  }
]
const callsToAction = [
  { name: 'Ver Demo', href: '#', icon: PlayIcon },
  { name: '(+51) 964 386 888', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Contactos',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#footer',
    icon: SupportIcon,
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  return (
    <>
    <div className="bg-primary-900 text-white w-full">
      <div className="container mx-auto px-4 py-3 text-base text-center">
        <span>Llámanos al (+51) 964-386-888 - Lunes a Domingo de 8 a 10 hrs. y de 18 a 20 hrs.</span>
      </div>
    </div>
      <Popover className="relative bg-black">
        {({ open }) => (
          <>
            <div className="container mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                  <a href="/">
                    <span className="sr-only">Workflow</span>
                    <img
                      className="h-auto w-48"
                      src={ Logo }
                      alt=""
                    />
                  </a>
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-800">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden md:flex space-x-8">
                  <Popover className="relative">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={classNames(
                            open ? 'text-primary-900' : 'text-white',
                            'group bg-black rounded-md inline-flex items-center text-base font-medium hover:text-primary focus:outline-none  focus:ring-offset-2 focus:ring-primary-800'
                          )}
                        >
                          <span>Nosotros</span>
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'text-primary-600' : 'text-white',
                              'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                          />
                        </Popover.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="absolute z-10 -ml-4 mt-6 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                {solutions.map((item) => (
                                  <a
                                    key={item.name}
                                    href={item.href}
                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                  >
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-primary-900" aria-hidden="true" />
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">{item.name}</p>
                                      <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                    </div>
                                  </a>
                                ))}
                              </div>
                              <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                {callsToAction.map((item) => (
                                  <div key={item.name} className="flow-root">
                                    <a
                                      href={item.href}
                                      className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                    >
                                      <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                      <span className="ml-3">{item.name}</span>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>

                  <a href="/portafolio" className="text-base font-medium text-white hover:text-primary-900">
                    Portafolio
                  </a>
                  {/* <a href="/price" className="text-base font-medium text-white hover:text-primary-900">
                    Precio
                  </a>
                  <a href="/blog" className="text-base font-medium text-white hover:text-primary-900">
                    Blog
                  </a> */}
                  <a href="#footer" className="text-base font-medium text-white hover:text-primary-900">
                    Contactos
                  </a>
                </Popover.Group>
                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                  <a href="http://accounts.sisreal.com/login" className="whitespace-nowrap inline-flex items-center justify-center px-5 py-2 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-200">
                    Inicia Sesión
                  </a>
                  <a
                    href="http://accounts.sisreal.com/register"
                    className="ml-2 whitespace-nowrap inline-flex items-center justify-center px-5 py-2 rounded-md shadow-sm text-base font-medium text-white bg-primary-900 hover:bg-primary-800 transform transition motion-reduce:transform-none hover:scale-105 duration-200 focus:outline-none"
                  >
                    Regístrate
                  </a>
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src={ Logo }
                          alt="Workflow"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-800">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {solutions.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <item.icon className="flex-shrink-0 h-6 w-6 text-primary-900" aria-hidden="true" />
                            <span className="ml-3 text-base font-medium text-white">{item.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                      <a href="/portafolio" className="text-base font-medium text-white hover:text-white">
                        Portafolio
                      </a>

                      {/* <a href="/" className="text-base font-medium text-white hover:text-white">
                        Blog
                      </a> */}
                      {resources.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="text-base font-medium text-white hover:text-white"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div>
                      <a
                        href="http://accounts.sisreal.com/login"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-900 hover:bg-indigo-700"
                      >
                        Regístrate
                      </a>
                      <p className="mt-6 text-center text-base font-medium text-gray-50">
                        Ya tiene cuenta?{' '}
                        <a href="http://accounts.sisreal.com/register" className="text-primary-900 hover:text-primary-800">
                          Inicia Sesión
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Router>
        <Switch>
          {/* <Route path='/portafolio' name='Portafolio' component={ IndexPortafolio } /> */}
          <Route path='/' name='Inicio' component={ IndexHome } />
        </Switch>
      </Router>
      

      {/* <div className="bg-primary-100 w-full mt-8">
        <div className="container mx-auto px-4">
          <div className="text-center py-8">
            <h5 className="text-2xl">CEO de <b><span className="text-primary-900">Sis</span>real</b></h5>
            <img className="m-auto pt-8 pb-4" src={ Yerson } alt="Yerson"/>
            <p className="text-sm tracking-wider pb-4">SOY YERSON YORDY CAMPOS DE LA CRUZ</p>
            <p className="max-w-3xl m-auto">Titulado de la carrera Computación e Informática del Instituto Continental. Hábil y práctico programador desarrollo de aplicación web; instalación y configuración de Software o redes, fortaleza responsabilidad, puntualidad, concentración, humildad y respeto.</p>
          </div>
        </div>
      </div> */}

      <div className="w-full">
        <div className="container mx-auto px-4 pt-8 pb-4">
          <div>
            <h5 className="text-2xl max-w-md m-auto text-center pb-8 pt-2">Ponga la claridad en el centro de su sitio web</h5>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
            <div>
              <div><img src={ Bootstrap } alt="Bootstrap"/></div>
              <div className="text-lg font-bold pt-6 pb-2.5">Bootstrap</div>
              <p>Delivering faster and more personalized support with shared screens and cool design systems for Figma</p>
            </div>
            <div>
              <div><img src={ CSS } alt="CSS"/></div>
              <div className="text-lg font-bold pt-6 pb-2.5">CSS</div>
              <p>Identify qualified customers with easy-to-use live chat messaging and AI-based Sales Bot</p>
            </div>
            <div>
              <div><img src={ Laravel } alt="Laravel"/></div>
              <div className="text-lg font-bold pt-6 pb-2.5">Laravel</div>
              <p>Delivering faster and more personalized support with shared screens and cool design systems for Figma</p>
            </div>
            <div>
              <div><img src={ React } alt="React"/></div>
              <div className="text-lg font-bold pt-6 pb-2.5">React</div>
              <p>You can toggle to any icon within Instances and customize outlined stroke to more bolder or lighter</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black w-full" id="footer">
        <div className="container mx-auto px-4">
          <footer className="text-white">
            <div className="grid grid-cols-12 gap-3 py-12">
              <div className="col-span-12 sm:col-span-3">
                <img src={ LogoFooter } alt="LogoFooter"/>
                <p className="text-base pt-7">Somos una empresa que te brinda servicios todo en la nube de forma comoda.</p>
                <div className="flex flex-wrap gap-2 pt-6">
                  <div><a><img src={ Facebook } alt="Facebook"/></a></div>
                  <div><a><img src={ Instagram } alt="Instagram"/></a></div>
                  <div><a><img src={ Twitter } alt="Twitter"/></a></div>
                  <div><a><img src={ Youtube } alt="Youtube"/></a></div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <h6 className="text-xl">Services</h6>
                <div className="pt-7">
                  <ul>
                    <li className="pb-3"><a>Home</a></li>
                    <li className="pb-3"><a>Nosotros</a></li>
                    <li className="pb-3"><a>Ayuda</a></li>
                    <li className="pb-3"><a>Contactos</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <h6 className="text-xl">Suscribete</h6>
                <div className="pt-7">
                <input className="flex-1 appearance-none border border-transparent w-full py-1 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-primary-900 focus:border-transparent" type="email" placeholder="Tú correo electrónico"/>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-3">
                <h6 className="text-xl">Contactenos</h6>
                <div className="pt-7">
                  <ul>
                    <li className="flex pb-3"> <img className="pr-2" src={ Mail } alt="Mail"/> info@sisreal.com</li>
                    <li className="flex pb-3"> <img className="pr-2" src={ Celular } alt="Celular"/> +51 964 386 888</li>
                    <li className="flex pb-3"> <img className="pr-2" src={ Maps } alt="Maps"/> Perú - Lima</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full bg-gray-400" style={{ height: 1 }}></div>
            <div className="py-6 text-xs flex justify-between flex-col sm:flex-row">
              <div>Copyright © 2021 sisreal.com. Todos los derechos reservados.</div>
              <div>
                <ul className="flex">
                  <li className="px-1">Terminos & Condiciones</li>
                  <li className="px-1">|</li>
                  <li className="px-1">Politicas de Privacidad</li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </div>
      


      {/* <div className="container mx-auto px-4">
        <br/>
        <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:border-primary-700 active:bg-primary-700 transition ease-in-out duration-150 cursor-not-allowed focus:outline-none" disabled="">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Processing
        </button>
        <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-800 hover:bg-primary-700 focus:border-primary-900 active:bg-primary-900 transition ease-in-out duration-150 focus:outline-none" disabled="">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Processing
        </button>

        <form className="flex w-full max-w-sm mx-auto space-x-3">
          <input className="flex-1 appearance-none border border-transparent w-full py-1 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-md rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-primary-900 focus:border-transparent" type="email" placeholder="Your email"/>
          <button className="flex-shrink-0 bg-primary-900 text-white text-base font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-900 focus:ring-offset-2 focus:ring-offset-primary-200" type="button">
            Sign up
          </button>
          <button className="ml-2 whitespace-nowrap inline-flex items-center justify-center px-5 py-2 rounded-md shadow-sm text-base font-medium text-white bg-primary-900 hover:bg-primary-800 focus:outline-none" type="button">
            Sign up
          </button>
        </form>

        <br/>

        <h1 className="text-7xl">Headline 1</h1>
        <h2 className="text-5xl">Headline 2</h2>
        <h3 className="text-4xl">Headline 3</h3>
        <h4 className="text-3xl">Headline 4</h4>
        <h5 className="text-2xl">Headline 5</h5>
        <h6 className="text-xl">Headline 6</h6>
        <br/>
        <p className="leading-normal">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, quia temporibus eveniet a libero incidunt suscipit laborum, rerum accusantium modi quidem, ipsam illum quis sed voluptatum quae eum fugit earum.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, quia temporibus eveniet a libero incidunt suscipit laborum, rerum accusantium modi quidem, ipsam illum quis sed voluptatum quae eum fugit earum.</p>
      </div> */}
    </>
  );
}

export default App;
